@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';
@import 'react-toastify/dist/ReactToastify.css';

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


.slick-slider.list-detail-slider {
  .slick-dots {
    position: absolute;
    bottom: 20px;
    z-index: 10;
    li {
      padding: 0;
      margin: 0 4px;
      width: auto;
      height: auto;
      button {
        width: 10px;
        height: 10px;
        padding: 0;
        border-radius: 50%;
        background: #fff;
        transition: 0.35s all ease;
        opacity: 0.8;
        &:before {
          content: none;
        }
      }
      &.slick-active button {
        width: 14px;
        height: 14px;
        opacity: 1;
      }
    }
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    &.slick-next {
      right: 20px;
    }
    &.slick-prev {
      left: 20px;
    }
  }
}
